@import '../Commons.scss';

.SideBarMenuItemView{
    text-align: left;
    display: flex;
    align-items: center;
    margin: 0 5px;

    a{
        color: white;
        text-decoration: none;
        display: block;
        padding: 10px;
        width: 100%;
        border-radius: 5px;

        .ItemContent{
            display: flex;
            flex-direction: row;
            align-items: center;
            overflow: hidden;
            width: 100%;
            white-space: nowrap;

            @include transition(width);

            &.collapsed{
                width: 35px;
            }

            .icon{
                
                zoom: 80%;
            }

            .label{
                font-weight: 500;
                margin-left: 10px;
                overflow: hidden;
            }
        }

        &:hover{
            background-color: #325ba8;
            color: white;
        }

        &:hover{
            & + .tooltip{
                display: inline-block;
                animation-duration: 0.4s;
                animation-name: tooltip;
            }
        }
    }

    .tooltip{
        position: absolute;
        background-color: black;
        left: 70px;
        padding: 5px;
        border-radius: 4px;
        width: 100px;
        // text-transform: capitalize;
        display: none;
    }
}

@keyframes tooltip {
    from {
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}