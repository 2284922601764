@import '../Commons.scss';

.SideBarMenu{
    background-color: #222;
    color: white;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    flex-direction: column;

    @include transition(width);

    &.expanded{
        width: 300px;
    }

    &.collapsed{
        width: 68px;
    }

    .menuButton{
        display: flex;
        justify-content: flex-end;
    }

    .hamburgerButton{
        cursor: pointer;
        border: none;
        background-color: transparent;
        color: white;
        padding: 10px;
        width: 68px;
        font-weight: bold;
    }
}